<template>
    <v-container fill-height fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="3">
                <v-card class="elevation-5 pa-0">
                    <v-toolbar color="primary" dark>
                        <v-spacer />
                        <v-toolbar-title>B-QUAL Test Library Login</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-form>
                        <v-card-text class="pb-0 mt-2">
                            <v-text-field
                                v-model="emailAddress"
                                outlined
                                label="Email Address"
                                prepend-icon="mdi-account"
                                type="text"
                                required />
                            <v-text-field
                                v-model="password"
                                outlined
                                label="Password"
                                prepend-icon="mdi-lock"
                                type="password"
                                required />
                        </v-card-text>
                        <v-divider />
                        <v-card-actions class="mx-3 py-4">
                            <app-button
                                :text="'Forgot Password'"
                                :outlined="true"
                                :click="forgotPassword" />
                            <v-spacer />
                            <v-btn
                                class="px-5 text-none"
                                color="primary"
                                rounded
                                @click="login()">
                                Log In
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    components: {
        appButton: () => import("@/components/AppButton")
    },

    data() {
        return {
            emailAddress: "",
            password: ""
        };
    },

    methods: {
        login() {
            const { dispatch } = this.$store;
            dispatch("authentication/login", {
                emailAddress: this.emailAddress,
                password: this.password
            });
        },
        forgotPassword() {
            this.$router.push("/request-reset-password");
        }
    }
};
</script>

<style lang="scss" scoped></style>
